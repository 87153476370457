import React from 'react';
import { Masks } from '../../Masks/masks';
import { useNavigate } from 'react-router-dom';

interface OrderProps {
  index: number;
  order: {
    id: string;
    pedido: string;
    comprador: {
      email: string;
      nome: string;
      telefone: string;
    };
    rifa: {
      afiliado: string;
      banco: string;
      link: string;
      sorteioGratuito: boolean;
      tempoExpiracao: string;
      nome: string;
      imagem: string;
    };
    valor: number;
    data_pedido: string;
    dominio: string;
    expira_em: string;
    orderId: string;
    quantidade: number;
    status: string;
  };
}

const Order: React.FC<OrderProps> = ({ index, order }) => {
  const masks = new Masks();
  const navigate = useNavigate();
  return (
    <div key={index} className="my-1 flex flex-col md:flex-row lg:flex-row items-center p-2 border bg-white rounded-xl w-full h-fit md:h-24 lg:h-24 min-h-24 border-gray-200 drop-shadow-lg">
      <img
        className="small-thumb-photo rounded-lg drop-shadow-lg object-cover cursor-pointer"
        onClick={() => navigate(`/checkout/${order.id}`)}
        src={order.rifa.imagem}
        alt=""
      />
      <div className="mx-2 text-start justify-between flex flex-row w-full max-w-full h-full">
        <div onClick={() => navigate(`/checkout/${order.id}`)} className="flex flex-col cursor-pointer">
          <h1 className="text-sm hidden md:block lg:block text-orange-700 montserrat-500">#{order.pedido}</h1>
          <h1 className="text-sm">{order.comprador.nome}</h1>
          <h1 className="text-sm">{order.comprador.telefone}</h1>
          <h1 className="text-sm montserrat-600">{order.rifa.nome}</h1>
        </div>
        <div className="flex flex-col text-center">
          <h1 className={`text-sm font-bold rounded-lg ${order.status == "expirado" || order.status == 'cancelado' ? "bg-red-300 text-red-600" : order.status == "pendente" ? "bg-orange-300 text-orange-600" : "bg-green-300 text-green-600"}`}>{order.status}</h1>
          <h1 className="text-sm">R$ {order.valor.toFixed(2)}</h1>
          <h1 className="text-sm">{masks.formatISODateToBrazilian(order.data_pedido)}</h1>
          <a href={`https://wa.me/+55${order.comprador.telefone}`} target='_blank' className="mt-1 text-xs font-bold rounded-lg bg-blue-400 cursor-pointer">Conversar</a>
        </div>
      </div>
    </div>
  );
}

export default Order;
