import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import api from '../../generalComponents/api';
import Swal from 'sweetalert2';
import React from 'react';

export default function SorteioCard({
  raffle,
  nivel,
}: {
  raffle: any;
  nivel: any;
}) {
  const navigate = useNavigate();
  const [enableBaixarCSV, setEnableBaixarCSV] = useState(true);

  function formatDate(dateString: any) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const strTime = `${hours}:${minutes} ${ampm}`;

    return `${day}/${month}/${year} ${strTime}`;
  }

  const downloadCSV = async () => {
    setEnableBaixarCSV(false);
    try {
      const domain = window.location.hostname;

      const response = await api.get('/cookie');
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      const response2 = await api.get('/raffles/exportCSV', {
        params: { dominio: domain, link: raffle?.link },
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response2.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `relatorio-${raffle?.nome}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Erro ao fazer download!',
        icon: 'error',
        text: 'Erro ao fazer download do arquivo .csv. Tente novamente mais tarde!',
      });
    } finally {
      setEnableBaixarCSV(true);
    }
  };

  const deleteRaffle = async () => {
    Swal.fire({
      title: 'Deletar Sorteio?',
      text: 'Você tem certeza que deseja deletar esse sorteio? Isso apagará todos os dados permanentemente!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (typeof document == 'undefined') return;
        if (typeof window == 'undefined') return;

        const domain = window.location.hostname;

        const response = await api
          .get('/cookie')
          .catch((error) => console.log(error));
        const _csrf = response?.data?.csrfToken;
        api.defaults.headers.common['X-CSRF-Token'] = _csrf;

        const response2 = await api
          .delete('/raffles/deleteRaffle', {
            params: {
              dominio: domain,
              link: raffle?.link,
              accessToken:
                'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
            },
          })
          .then((response) => {
            Swal.fire({
              title: 'Sorteio deletado!',
              icon: 'success',
              text: 'O sorteio foi deletado com sucesso!',
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {
            if (error.response.data.code) {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: `ERRO - ${error.response.data.code}`,
                text: `${error.response.data.error}`,
              });
            }
          });
      } else {
        return;
      }
    });
  };

  const handleUpdateStatus = async (status: string) => {
    Swal.fire({
      title: 'Alterar status do sorteio?',
      text: `Você tem certeza que deseja alterar o status do sorteio para ${status}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (typeof document == 'undefined') return;
        if (typeof window == 'undefined') return;

        const domain = window.location.hostname;

        const response = await api
          .get('/cookie')
          .catch((error) => console.log(error));
        const _csrf = response?.data?.csrfToken;
        api.defaults.headers.common['X-CSRF-Token'] = _csrf;

        await api
          .put('/raffles/updateStatus', {
            dominio: domain,
            link: raffle?.link,
            status: status,
            accessToken:
              'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
          })
          .then((response) => {
            Swal.fire({
              title: 'Status alterado!',
              icon: 'success',
              text: 'O status do sorteio foi alterado com sucesso!',
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {
            console.log(error);
            if (error.response.data.code) {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: `ERRO - ${error.response.data.code}`,
                text: `${error.response.data.error}`,
              });
            }
          });
      } else {
        return;
      }
    });
  };

  return (
    <>
      <div className="flex flex-col w-full p-2 bg-gray-200 shadow-lg rounded-lg my-1">
        <div
          onClick={() => navigate(`/singleraffle/${raffle.link}`)}
          className="flex flex-grow w-full cursor-pointer"
        >
          <img
            src={raffle.imagens[0]}
            alt="Sorteio"
            className="w-24 h-24 rounded-lg object-cover"
          />
          <div className="flex flex-col px-2 py-1">
            <h1 className="montserrat-500">{raffle.nome}</h1>
            <h1 className="montserrat-400">{formatDate(raffle.dataSorteio)}</h1>
            {raffle?.ganhador?.nome ? (
              <>
                <h1 className="montserrat-400">{raffle.ganhador?.nome}</h1>
              </>
            ) : (
              <>
                <h1 className="montserrat-400">Nenhum ganhador definido.</h1>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-grow w-full lg:w-1/2 justify-between pt-2">
          {raffle.status == 'ativo' ? (
            <>
              <div className="group relative">
                <button
                  title="Alterar status do sorteio"
                  onClick={() => handleUpdateStatus('aguardando')}
                  className="bg-green-500 text-white w-fit h-fit rounded-lg p-1 flex min-w-8 min-h-8 justify-center items-center hover:bg-green-600 transition ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.2em"
                    height="1.2em"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="white"
                      fillRule="evenodd"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="4"
                      d="m4 24l5-5l10 10L39 9l5 5l-25 25z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <div className="absolute bottom-full w-fit mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2">
                  Alterar status do sorteio
                </div>
              </div>
            </>
          ) : raffle.status == 'aguardando' ? (
            <>
              <div className="group relative">
                <button
                  onClick={() => handleUpdateStatus('ativo')}
                  className="bg-yellow-500 text-white rounded-lg p-1 flex min-w-8 min-h-8 justify-center items-center hover:bg-yellow-600 transition ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.2em"
                    height="1.2em"
                    viewBox="0 0 24 24"
                  >
                    <g fill="none">
                      <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                      <path
                        fill="white"
                        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m0 2a1 1 0 0 1 .993.883L13 7v4.586l2.707 2.707a1 1 0 0 1-1.32 1.497l-.094-.083l-3-3a1 1 0 0 1-.284-.576L11 12V7a1 1 0 0 1 1-1"
                      />
                    </g>
                  </svg>
                </button>
                <div className="absolute bottom-full w-fit mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2">
                  Alterar status do sorteio
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="group relative">
                <button className="bg-stone-800 text-white rounded-lg p-1 flex min-w-8 min-h-8 justify-center items-center hover:bg-stone-950 transition ease-in-out">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.2em"
                    height="1.2em"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="white"
                      d="M5 4v24h2v-8h20V4zm2 2h3v3h3V6h3v3h3V6h3v3h3v3h-3v3h3v3h-3v-3h-3v3h-3v-3h-3v3h-3v-3H7v-3h3V9H7zm3 6v3h3v-3zm3 0h3V9h-3zm3 0v3h3v-3zm3 0h3V9h-3z"
                    />
                  </svg>
                </button>
                <div className="absolute bottom-full w-fit mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2">
                  Sorteio finalizado
                </div>
              </div>
            </>
          )}
          <div className="group relative">
            <button
              title="Editar Sorteio"
              onClick={() => navigate(`/dashboard/editraffle/${raffle.link}`)}
              className="bg-blue-500 text-white rounded-lg p-1 flex min-w-8 min-h-8 justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 24 24"
              >
                <g
                  fill="none"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.95"
                >
                  <path d="m16.475 5.408l2.117 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621" />
                  <path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" />
                </g>
              </svg>
            </button>
            <div className="absolute bottom-full w-fit mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2">
              Editar sorteio
            </div>
          </div>
          <div className="group relative">
            <button
              onClick={() =>
                navigate(`/dashboard/sorteios/newwinner/${raffle.link}`)
              }
              className="bg-amber-600 text-white rounded-lg p-1 flex min-w-8 min-h-8 justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 20 20"
              >
                <path
                  fill="white"
                  fillRule="evenodd"
                  d="M10 1c-1.828 0-3.623.149-5.371.435a.75.75 0 0 0-.629.74v.387q-1.24.235-2.445.564a.75.75 0 0 0-.552.698L1 4a5 5 0 0 0 4.506 4.976a6 6 0 0 0 2.946 1.822A6.5 6.5 0 0 1 7.768 13H7.5A1.5 1.5 0 0 0 6 14.5V17h-.75C4.56 17 4 17.56 4 18.25c0 .414.336.75.75.75h10.5a.75.75 0 0 0 .75-.75c0-.69-.56-1.25-1.25-1.25H14v-2.5a1.5 1.5 0 0 0-1.5-1.5h-.268a6.5 6.5 0 0 1-.684-2.202a6 6 0 0 0 2.946-1.822a5 5 0 0 0 4.503-5.152a.75.75 0 0 0-.552-.698A32 32 0 0 0 16 2.562v-.387a.75.75 0 0 0-.629-.74A33 33 0 0 0 10 1M2.525 4.422Q3.255 4.24 4 4.09V5c0 .74.134 1.448.38 2.103a3.5 3.5 0 0 1-1.855-2.68m14.95 0a3.5 3.5 0 0 1-1.854 2.68A6 6 0 0 0 16 5v-.91q.744.149 1.475.332"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <div className="absolute bottom-full w-fit mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2">
              Definir ganhador
            </div>
          </div>
          <div className="group relative">
            <button
              onClick={() =>
                navigate(`/dashboard/sorteios/ordersbyraffle/${raffle.link}`)
              }
              className="bg-red-600 text-white rounded-lg p-1 flex min-w-8 min-h-8 justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25q0-.075.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"
                />
              </svg>
            </button>
            <div className="absolute bottom-full w-fit mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2">
              Ver pedidos
            </div>
          </div>
          <div className="group relative">
            <button
              onClick={() =>
                navigate(`/dashboard/sorteios/titulospremiados/${raffle.link}`)
              }
              className="bg-purple-500 text-white rounded-lg p-1 flex min-w-8 min-h-8 justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  d="M15.58 16.8L12 14.5l-3.58 2.3l1.08-4.12L6.21 10l4.25-.26L12 5.8l1.54 3.94l4.25.26l-3.29 2.68M20 12a2 2 0 0 1 2-2V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2a2 2 0 0 1-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 1-2-2"
                />
              </svg>
            </button>
            <div className="absolute bottom-full w-fit mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2">
              Títulos premiados
            </div>
          </div>
          {nivel != '1' && nivel != '0' ? (
            <>
              <div className="group relative">
                <button
                  onClick={() =>
                    navigate(`/dashboard/rastreadores/${raffle.link}`)
                  }
                  className="bg-stone-950 text-white rounded-lg p-1 flex min-w-8 min-h-8 justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.2em"
                    height="1.2em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="white"
                      d="M6 23H3q-.825 0-1.412-.587T1 21v-3h2v3h3zm12 0v-2h3v-3h2v3q0 .825-.587 1.413T21 23zm-6-4.5q-3 0-5.437-1.775T3 12q1.125-2.95 3.563-4.725T12 5.5t5.438 1.775T21 12q-1.125 2.95-3.562 4.725T12 18.5m0-3q1.45 0 2.475-1.025T15.5 12t-1.025-2.475T12 8.5T9.525 9.525T8.5 12t1.025 2.475T12 15.5m0-2q-.625 0-1.062-.437T10.5 12t.438-1.062T12 10.5t1.063.438T13.5 12t-.437 1.063T12 13.5M1 6V3q0-.825.588-1.412T3 1h3v2H3v3zm20 0V3h-3V1h3q.825 0 1.413.588T23 3v3z"
                    />
                  </svg>
                </button>
                <div className="absolute bottom-full w-fit mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2">
                  Ver rastreadores
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="group relative">
            {enableBaixarCSV ? (
              <>
                <button
                  onClick={() => downloadCSV()}
                  className="bg-gray-500 text-white rounded-lg p-1 flex min-w-8 min-h-8 justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.2em"
                    height="1.2em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="white"
                      d="M9.8 21.75q-3.425-.775-5.613-3.5T2 12t2.188-6.25T9.8 2.25q.475-.125.838.175t.362.8q0 .35-.2.625t-.55.35Q7.5 4.825 5.75 7T4 12t1.75 5t4.5 2.8q.35.075.55.35t.2.625q0 .5-.362.8t-.838.175m4.4 0q-.475.125-.837-.175t-.363-.8q0-.35.2-.625t.55-.35q.675-.15 1.313-.413t1.212-.637q.275-.2.613-.15t.587.3q.35.35.3.813t-.45.737q-.725.45-1.513.775t-1.612.525m4.7-4.25q-.25-.25-.3-.587t.15-.613q.375-.575.638-1.225t.412-1.325q.075-.35.35-.537t.625-.188q.5 0 .8.363t.175.837q-.2.825-.525 1.613t-.775 1.512q-.275.4-.737.45t-.813-.3m1.875-6.5q-.35 0-.625-.2t-.35-.55q-.15-.675-.413-1.312t-.637-1.213q-.2-.3-.15-.637t.3-.588q.35-.35.813-.3t.737.475q.45.725.775 1.513T21.75 9.8q.125.475-.175.838t-.8.362m-4.5-5.75q-.575-.375-1.212-.638T13.75 4.2q-.35-.075-.55-.35t-.2-.625q0-.5.363-.8t.837-.175q.825.2 1.613.525t1.512.775q.425.275.475.738t-.3.812q-.25.25-.587.3t-.638-.15m-3.3 7.875l1.85-1.85q.3-.3.725-.3t.725.3t.288.725t-.313.725L12.675 16.3q-.275.275-.7.275t-.7-.275L7.65 12.65q-.3-.3-.288-.712t.313-.713t.713-.3t.712.3l1.875 1.9V8q0-.425.288-.712T11.975 7t.713.288t.287.712z"
                    />
                  </svg>
                </button>
              </>
            ) : (
              <>
                <button className="bg-gray-600 text-white rounded-lg p-1 flex min-w-8 min-h-8 justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.2em"
                    height="1.2em"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-width="1.95"
                    >
                      <path
                        stroke-dasharray="2 4"
                        stroke-dashoffset="6"
                        d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          dur="0.45s"
                          repeatCount="indefinite"
                          values="6;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="30"
                        stroke-dashoffset="30"
                        d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.075s"
                          dur="0.225s"
                          values="30;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="10"
                        stroke-dashoffset="10"
                        d="M12 8v7.5"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.375s"
                          dur="0.15s"
                          values="10;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="6"
                        stroke-dashoffset="6"
                        d="M12 15.5l3.5 -3.5M12 15.5l-3.5 -3.5"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.525s"
                          dur="0.15s"
                          values="6;0"
                        />
                      </path>
                    </g>
                  </svg>
                </button>
              </>
            )}
            <div className="absolute bottom-full w-fit mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2">
              Baixar relatório
            </div>
          </div>
          <div className="bg-transparent border-2 text-sm border-green-500 text-green-500 rounded-lg p-1">
            {parseFloat(raffle.porcentagemVendida).toFixed(2)}%
          </div>
          <div className="group relative">
            <button
              onClick={() => deleteRaffle()}
              className="bg-red-700 text-white rounded-lg p-1 flex min-w-8 min-h-8 justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.95"
                  d="M4 7h16m-10 4v6m4-6v6M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M9 7V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3"
                />
              </svg>
            </button>
            <div className="absolute bottom-full w-fit mb-2 hidden group-hover:block bg-gray-700 text-white text-xs rounded py-1 px-2">
              Deletar sorteio
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
