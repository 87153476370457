import {
  Box,
  breadcrumbsClasses,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Autorizacao from '../generalComponents/Autorizacao';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../generalComponents/api';
import Swal from 'sweetalert2';
import OrderComplete from '../AllOrders/components/OrderComplete';
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import { jwtDecode } from 'jwt-decode';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import DashboardDrawer from '../generalComponents/DashboardDrawer';

export default function OrdersByRaffle() {
  const [autorizacao, setAutorizacao] = useState(false); // alterar depois
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [orders, setOrders] = useState([] as any);
  const [filteredOrders, setFilteredOrders] = useState([] as any);
  const [link, setLink] = useState('');
  const [open, setOpen] = useState(false);
  const [dataInicial, setDataInicial] = useState<Dayjs | null>(null);
  const [dataFinal, setDataFinal] = useState<Dayjs | null>(null);
  const [ordernar, setOrdenar] = useState('');
  const [filtrosAplicados, setFiltrosAplicados] = useState(false);
  const [lastDocId, setLastDocId] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  //resultados do filtro
  const [enableMajorOrMinor, setEnableMajorOrMinor] = useState(true);
  const [minorOrMajor, setMinorOrMajor] = useState('0');
  //controle do drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const handleClose = () => setOpen(false);

  const authorization = async () => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const token = localStorage.getItem('appSession') || '';
    if (token == '') {
      setAutorizacao(false);
      return;
    }
    let decodeJwt = { email: '' };
    decodeJwt = jwtDecode(token);

    await api
      .get('/client/authorizate', { params: { email: decodeJwt.email } })
      .then((response) => {
        setAutorizacao(true);
      })
      .catch((error) => {
        setAutorizacao(false);
      });
  };

  useEffect(() => {
    authorization();
  }, []);

  const getOrders = async (isNextPage: boolean) => {
    if (typeof document === 'undefined') return;
    if (typeof window === 'undefined') return;

    if (isLoading || (!hasMore && isNextPage)) return;

    setIsLoading(true);

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const pathSegments = location.pathname.split('/');
    const segment = pathSegments[pathSegments.length - 1];
    setLink(segment);

    let data
    if (lastDocId != "") {
      data = {
        dominio: domain,
        accessToken:
          'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
        link: segment,
        lastDocId
      }
    } else {
      data = {
        dominio: domain,
        accessToken:
          'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
        link: segment
      }
    }

    await api
      .get('/orders/getOrderByRaffle', {
        params: data
      })
      .then((response: any) => {
        setOrders(response.data.data);
        setFilteredOrders(response.data.data)
        if (response.data.lastDocId) setLastDocId(response.data.lastDocId)
        setHasMore(response.data.data.length > 0)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.code !== 4000) {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        }
      });
  };

  useEffect(() => {
    getOrders(true);
  }, []);

  useEffect(() => {
    handleFilter();
  }, [searchTerm]);

  const handleFilter = () => {
    setFilteredOrders(filterOrders(searchTerm));
  };

  const filterOrders = (term: string) => {
    return orders.filter((order: any) => {
      return (
        order.comprador.nome.toLowerCase().includes(term.toLowerCase()) ||
        order.comprador.telefone.toLowerCase().includes(term.toLowerCase()) ||
        order.pedido.toLowerCase().includes(term.toLowerCase())
      );
    });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: '20px',
  };

  const handleFiltrarPedidos = () => {
    let filterOrders = orders;

    if (dataInicial != null) {
      filterOrders = filterOrders.filter(
        (order: {
          data_pedido: string | number | dayjs.Dayjs | Date | null | undefined;
        }) => {
          const dataPedido = dayjs(order.data_pedido, 'M/D/YYYY h:mm:ss A');
          return dataPedido.isAfter(dataInicial);
        },
      );
    }

    if (dataFinal != null) {
      filterOrders = filterOrders.filter(
        (order: {
          data_pedido: string | number | dayjs.Dayjs | Date | null | undefined;
        }) => {
          const dataPedido = dayjs(order.data_pedido, 'M/D/YYYY h:mm:ss A');
          return dataPedido.isBefore(dataFinal) || dataPedido.isSame(dataFinal);
        },
      );
    }

    if (ordernar !== '') {
      switch (ordernar) {
        case 'menorNumero':
          getMinorCota();
          break;
        case 'maiorNumero':
          getMajorCota();
          break;
        case 'maisCotas':
          filterOrders.sort((a: any, b: any) => b.quantidade - a.quantidade);
          break;
        case 'menosCotas':
          filterOrders.sort((a: any, b: any) => a.quantidade - b.quantidade);
          break;
        case 'maisRecente':
          filterOrders.sort(
            (a: any, b: any) =>
              dayjs(b.data_pedido, 'M/D/YYYY h:mm:ss A').valueOf() -
              dayjs(a.data_pedido, 'M/D/YYYY h:mm:ss A').valueOf(),
          );
          break;
        case 'maisAntigo':
          filterOrders.sort(
            (a: any, b: any) =>
              dayjs(a.data_pedido, 'M/D/YYYY h:mm:ss A').valueOf() -
              dayjs(b.data_pedido, 'M/D/YYYY h:mm:ss A').valueOf(),
          );
          break;
        default:
          console.log('NENHUM');
      }
    }

    setFilteredOrders(filterOrders);
    setFiltrosAplicados(true);
    handleClose();
  };

  const limparFiltros = () => {
    setFilteredOrders(orders);
    setOrdenar('');
    setDataFinal(null);
    setDataInicial(null);
    setFiltrosAplicados(false);
    setEnableMajorOrMinor(true);
    setMinorOrMajor('0');
  };

  const getMinorCota = async () => {
    setEnableMajorOrMinor(false);
    if (typeof document === 'undefined') return;
    if (typeof window === 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const pathSegments = location.pathname.split('/');
    const segment = pathSegments[pathSegments.length - 1];
    setLink(segment);

    await api
      .get('/orders/getMinorOrderByRaffle', {
        params: {
          dominio: domain,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
          link: segment,
        },
      })
      .then((response: any) => {
        setFilteredOrders([response.data.order]);
        setFiltrosAplicados(true);
        setEnableMajorOrMinor(true);
        setMinorOrMajor(response.data.cota);
      })
      .catch((error) => {
        console.log(error);
        setEnableMajorOrMinor(true);
        if (error.response.data.code !== 4000) {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        }
      });
  };

  const getMajorCota = async () => {
    setEnableMajorOrMinor(false);
    if (typeof document === 'undefined') return;
    if (typeof window === 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const pathSegments = location.pathname.split('/');
    const segment = pathSegments[pathSegments.length - 1];
    setLink(segment);

    await api
      .get('/orders/getMajorOrderByRaffle', {
        params: {
          dominio: domain,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
          link: segment,
        },
      })
      .then((response: any) => {
        setFilteredOrders([response.data.order]);
        setFiltrosAplicados(true);
        setEnableMajorOrMinor(true);
        setMinorOrMajor(response.data.cota);
      })
      .catch((error) => {
        setEnableMajorOrMinor(true);
        console.log(error);
        if (error.response.data.code !== 4000) {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        }
      });
  };

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <div className="flex flex-col w-full min-h-screen items-center bg-orange-50">
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="filtros"
              aria-describedby="filtros nos pedidos"
            >
              <Box sx={style}>
                <Typography
                  className="text-center"
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Filtrar pedidos
                </Typography>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '96%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Data inicial"
                      value={dataInicial}
                      onChange={(newValue: Dayjs | null) => {
                        setDataInicial(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '96%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Data final"
                      value={dataFinal}
                      onChange={(newValue: Dayjs | null) => {
                        setDataFinal(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '96%' },
                    width: '100%',
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl color="success" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Ordenar por
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Ordenar por "
                      value={ordernar}
                      onChange={(e: any) => setOrdenar(e.target.value)}
                      color="success"
                    >
                      <MenuItem value={'maisCotas'}>
                        Maior quantidade de cotas
                      </MenuItem>
                      <MenuItem value={'menosCotas'}>
                        Menor quantidade de cotas
                      </MenuItem>
                      <MenuItem value={'maisRecente'}>Mais recente</MenuItem>
                      <MenuItem value={'maisAntigo'}>Mais antigo</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <div className="flex w-full items-center justify-around">
                  <button
                    onClick={() => handleClose()}
                    className="w-fit h-fit py-2 px-6 bg-red-500 text-white font-medium rounded-xl hover:bg-red-600 transition ease-in-out"
                  >
                    Fechar
                  </button>
                  <button
                    onClick={() => handleFiltrarPedidos()}
                    className="w-fit h-fit py-2 px-6 bg-green-500 text-white font-medium rounded-xl hover:bg-green-600 transition ease-in-out"
                  >
                    Filtrar
                  </button>
                </div>
              </Box>
            </Modal>
            <div className="flex flex-row justify-between w-screen p-5 hover:shadow-inner rounded-lg">
              <button onClick={() => navigate('/')} className="flex flex-row">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
              <button
                className="block md:hidden lg:hidden"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.9em"
                  height="1.9em"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="black"
                    d="M2 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m.5 4.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"
                  />
                </svg>
              </button>
              <DashboardDrawer
                isOpen={isOpenDrawer}
                setIsOpen={setIsOpenDrawer}
                page="sorteios"
                nivel={'1'}
              />
            </div>
            <div className="flex flex-col md:flex-row lg:flex-row w-full h-full p-2">
              <div className="hidden lg:flex md:flex flex-col min-w-96">
                <DashboardSideTools page="pedidos" />
              </div>
              <div className="flex flex-col w-full max-h-screen justify-center items-center p-2">
                <div className="flex flex-col w-full h-dvh bg-white p-2 rounded-xl">
                  <div className="flex flex-row w-full items-center max-h-full bg-white p-2 rounded-xl">
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '98%' },
                        width: '100%',
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="outlined-basic"
                        label="Pesquisar por nome ou número de telefone"
                        variant="outlined"
                        color="success"
                        value={searchTerm}
                        onChange={(e: any) => setSearchTerm(e.target.value)}
                      />
                    </Box>
                    <button onClick={(e) => setOpen(true)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2em"
                        height="2em"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#FEF08A"
                          d="M14 12v7.88c.04.3-.06.62-.29.83a.996.996 0 0 1-1.41 0l-2.01-2.01a.99.99 0 0 1-.29-.83V12h-.03L4.21 4.62a1 1 0 0 1 .17-1.4c.19-.14.4-.22.62-.22h14c.22 0 .43.08.62.22a1 1 0 0 1 .17 1.4L14.03 12z"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-row w-full items-center justify-around max-h-full bg-white p-2 rounded-xl">
                    {enableMajorOrMinor ? (
                      <>
                        <button
                          onClick={(e) => getMinorCota()}
                          className="text-sm p-2 font-bold rounded-lg bg-yellow-200 text-black"
                        >
                          Menor do dia
                        </button>
                        <button
                          onClick={(e) => getMajorCota()}
                          className="text-sm p-2 font-bold rounded-lg bg-yellow-200 text-black"
                        >
                          Maior do dia
                        </button>
                      </>
                    ) : (
                      <>
                        <button className="text-sm p-2 font-bold rounded-lg bg-yellow-300 text-black">
                          Buscando cota...
                        </button>
                        <button className="text-sm p-2 font-bold rounded-lg bg-yellow-300 text-black">
                          Buscando cota...
                        </button>
                      </>
                    )}
                    {filtrosAplicados ? (
                      <>
                        <button
                          onClick={(e) => limparFiltros()}
                          className="text-sm p-2 font-bold rounded-lg bg-yellow-200 text-black"
                        >
                          Limpar Filtros
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex flex-col w-full max-h-full p-2 overflow-y-auto">
                    {filteredOrders.length > 0 ? (
                      filteredOrders.map((order: any, index: number) => (
                        <OrderComplete
                          key={index}
                          index={index}
                          order={order}
                          cota={minorOrMajor || '0'}
                        />
                      ))
                    ) : (
                      <p>Nenhum pedido encontrado</p>
                    )}

                    {/* Botões de Paginação */}
                    <div className="flex justify-between mt-4">
                      <button
                        onClick={() => getOrders(false)} // Reinicia a primeira página
                        disabled={isLoading || !orders.length}
                        className="px-4 py-2 bg-green-500 text-white rounded disabled:opacity-50"
                      >
                        Primeira Página
                      </button>

                      <button
                        onClick={() => getOrders(true)} // Próxima página
                        disabled={isLoading || !hasMore}
                        className="px-4 py-2 bg-green-500 text-white rounded disabled:opacity-50"
                      >
                        Próxima Página
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
