import HeaderMain from '../generalComponents/HeaderMain';
import { useState, useEffect } from 'react';
import { Masks } from '../Masks/masks';
import api from '../generalComponents/api';
import Swal from 'sweetalert2';
import { setDefaultResultOrder } from 'dns';
import { io } from 'socket.io-client';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';

export default function Checkout() {
  const masks = new Masks();
  const [copied, setCopied] = useState(false);
  const [status, setStatus] = useState('aprovado');
  const [verNumeros, setVerNumeros] = useState(false);
  const [cotaPremiada, setCotaPremiada] = useState('');
  const [qrCode64, setQrCode64] = useState('');
  const [qrCodeCC, setQrCodeCC] = useState('');
  const [cotas, setCotas] = useState([] as string[]);
  const [tempoExpiracao, setTempoExpiracao] = useState('');
  const [rifa, setRifa] = useState({} as any);
  const [comprador, setComprador] = useState({} as any);
  const [dataPedido, setDataPedido] = useState('');
  const [dataExpiracao, setDataExpiracao] = useState('');
  const [quantidade, setQuantidade] = useState(0);
  const [valor, setValor] = useState(0);
  const [raffleLink, setRaffleLink] = useState('');
  const [titulosPremiados, setTitulosPremiados] = useState([] as any);
  //configurações dos analitics (Google Tag Manager e Pixel do Facebook)
  const [client, setClient] = useState({} as any);

  const options = {
    autoConfig: true, // Se quiser que o pixel configure automaticamente os eventos padrão
    debug: false, // Habilite o modo debug para ver logs no console
  };

  const getClientInfo = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/client/getAccountConfig', { params: { dominio: domain } })
      .then((response) => {
        setClient(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getClientInfo();
  }, []);

  useEffect(() => {
    //Facebook Pixel
    ReactPixel.init(`${client?.analytics?.pixelID}`, undefined, options);
    ReactPixel.pageView();

    //Facebook conversion api
    if (client?.analytics?.tokenApi && client?.analytics?.pixelID) {
      if (client?.analytics?.tokenApi != 'empty') {
        handleConversionAPI(
          client?.analytics?.pixelID,
          client?.analytics?.tokenApi,
          'pageView',
          0,
        );
      }
    }

    //Google Tag Manager
    const tagManagerArgs = {
      gtmId: client?.analytics?.googleAnalytics,
    };
    TagManager.initialize(tagManagerArgs);
    window.dataLayer.push({
      event: 'pageview',
    });
  }, [client]);

  const handleConversionAPI = async (
    pixelID: string,
    token: string,
    eventName: string,
    value: number,
  ) => {
    console.log('entrou no conversion API');
    let data;
    if (eventName == 'pageView') {
      data = [
        {
          event_name: eventName,
          event_time: new Date().getTime(),
          event_source_url: window.location.href,
          action_source: 'website',
        },
      ];
    } else {
      data = [
        {
          event_name: eventName,
          event_time: new Date().getTime(),
          custom_data: {
            currency: 'brl',
            value: value,
          },
          event_source_url: window.location.href,
          action_source: 'website',
        },
      ];
    }
    const response = await api
      .post(
        `https://graph.facebook.com/v20.0/${pixelID}/events?access_token=${token}`,
        { data },
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEventInitiateCheckout = (quantidade: number, valor: number) => {
    //Facebook Pixel
    ReactPixel.track('InitiateCheckout', {
      num_items: quantidade,
      currency: 'BRL',
      value: parseFloat(valor.toFixed(2)),
    });

    //facebook conversion api
    if (client?.analytics?.tokenApi && client?.analytics?.pixelID) {
      if (client?.analytics?.tokenApi != 'empty') {
        handleConversionAPI(
          client?.analytics?.pixelID,
          client?.analytics?.tokenApi,
          'InitiateCheckout',
          parseFloat(valor.toFixed(2)),
        );
      }
    }

    //google GTM
    window.dataLayer.push({
      event: 'initiateCheckout',
      eventCategory: 'Ecommerce',
      eventAction: 'Initiate Checkout',
      eventLabel: 'Checkout',
      eventValue: 1,
      eventQuantity: quantidade,
    });
  };

  const handleEventPurchase = (
    quantidade: number,
    valor: number,
    userId: string,
  ) => {
    //Facebook Pixel
    trackPixelPurchase(valor, quantidade, userId);

    //facebook conversion api
    if (client?.analytics?.tokenApi && client?.analytics?.pixelID) {
      if (client?.analytics?.tokenApi != 'empty') {
        handleConversionAPI(
          client?.analytics?.pixelID,
          client?.analytics?.tokenApi,
          'Purchase',
          parseFloat(valor.toFixed(2)),
        );
      }
    }

    //google GTM
    window.dataLayer.push({
      event: 'purchase',
      eventCategory: 'Ecommerce',
      eventAction: 'Purchase',
      eventLabel: 'Compra Aprovada',
      eventValue: valor,
      eventQuantity: quantidade,
    });
  };

  const trackPixelPurchase = async (
    valor: number,
    quantidade: number,
    userId: string,
  ) => {
    console.log('entrou no trackPixelPurchase', valor, quantidade, userId);
    ReactPixel.track('Purchase', {
      currency: 'BRL',
      value: parseFloat(valor.toFixed(2)),
      contents: [
        {
          id: userId,
          quantity: quantidade,
        },
      ],
      content_type: 'product', // Tipo de conteúdo
      num_items: quantidade, // Número de itens comprados
    });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(qrCodeCC);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const handleGetRaffle = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .get('/raffles/getRaffle', {
        params: {
          dominio: domain,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj12312394a8945557',
          link: raffleLink,
        },
      })
      .then((response) => {
        response.data[0].cotasPremiadas.forEach((obj: any) =>
          titulosPremiados.push(obj),
        );
        if (titulosPremiados?.length > 0) {
          handleCompareTitulos();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (raffleLink !== '') {
      handleGetRaffle();
    }
  }, [raffleLink]);

  const handleCompareTitulos = () => {
    if (cotas?.length == 0) return;
    if (titulosPremiados?.length == 0) return;
    titulosPremiados?.map((titulo: any, index: number) => {
      for (let i = 0; i < cotas?.length; i++) {
        if (titulo?.titulo == cotas[i]) {
          setCotaPremiada(titulo?.titulo as string);
          break;
        }
      }
    });
  };

  useEffect(() => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const getOrder = async () => {
      const pathSegments = location.pathname.split('/');
      const segment = pathSegments[pathSegments?.length - 1];

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      await api
        .get('/orders/getOrder', {
          params: {
            pedido: segment,
            accessToken:
              'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj12312394a8945557',
          },
        })
        .then((response) => {
          if (response.data.data.numerosPedido) {
            setCotas(response.data.data.numerosPedido);
          }
          setStatus(response.data.data.status);
          setQrCodeCC(response.data.data.qrCodeCC);
          setTempoExpiracao(response.data.data.rifa.tempoExpiracao);
          setRifa(response.data.data.rifa);
          setDataPedido(response.data.data.data_pedido);
          setDataExpiracao(response.data.data.expira_em);
          setQuantidade(response.data.data.quantidade);
          setComprador(response.data.data.comprador);
          setValor(response.data.data.valor);
          setRaffleLink(response.data.data.rifa.link);
          if (response.data.data.status == 'pendente') {
            setQrCode64(response.data.data.qrCode64);
            setQrCodeCC(response.data.data.qrCode);
          }
          if (response.data.data.status == 'aprovado') {
            setVerNumeros(true);
          }
          socketConnection(
            segment,
            response.data.data.valor,
            response.data.data.quantidade,
          );
          handleEventInitiateCheckout(
            response.data.data.quantidade,
            response.data.data.valor,
          );
        })
        .catch((error) => {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        });
    };

    getOrder();
  }, []);

  const handleNewTrack = async (valor: number, quantidade: number) => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;
    const rastreador = localStorage.getItem('rastreador') as string;

    if (rastreador === null || rastreador == '' || rastreador == undefined)
      return;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .post('/tracks/newTrackSale', {
        dominio: domain,
        id: rastreador,
        accessToken:
          'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
        valor: valor,
        quantidade: quantidade,
        link: raffleLink,
      })
      .then((response) => {
        localStorage.removeItem('rastreador');
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem('rastreador');
      });
  };

  useEffect(() => {
    if (status === 'aprovado') {
      if (quantidade > 0) {
        if (valor == 0) {
          handleNewTrack(valor, quantidade);
        }
      }
    }
  }, [quantidade]);

  const handleNewAfiliadoSale = async (valor: number, quantidade: number) => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;
    const afiliado = localStorage.getItem('afiliado') as string;

    if (afiliado === null || afiliado == '' || afiliado == undefined) return;

    let valorFinal = 0;
    if (valor > 0) {
      valorFinal = parseFloat((valor * 0.1).toFixed(2));
    }

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .put('/buyers/newAfiliadoSale', {
        dominio: domain,
        accessToken:
          'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
        afiliado: afiliado,
        quantidade: quantidade,
        valor: valorFinal,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const getBaseDomain = () => {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    // Se houver mais de duas partes, remova a primeira (subdomínio)
    if (parts.length > 2) {
      parts.shift();
    }
    return parts.join('.');
  };

  // Função para construir a URL base dinamicamente
  const getBaseURL = () => {
    const baseDomain = getBaseDomain();
    return `https://api.${baseDomain}`;
  };

  const socketConnection = (payID: string, valorPixel: number, quantidadePixel: number) => {
    if (typeof window == 'undefined') return;
    if (typeof document == 'undefined') return;
    const userId = payID;
    const socket = io(getBaseURL(), {
      query: {
        orderId: userId,
      },
      transports: ['websocket'],
      upgrade: false,
    });

    socket.emit('subscribeToChannel', userId);

    socket.on('connect', () => {});

    socket.on('message', (msj: any) => {
      console.log(msj);
    });

    socket.on('notification', (data: any) => {
      if (data.type === 'paymentApproved') {
        console.log('Pagamento aprovado', valor, quantidade);
        console.log('Pagamento aprovado', valorPixel, quantidadePixel);
        handleNewAfiliadoSale(valorPixel, quantidadePixel);
        handleNewTrack(valorPixel, quantidadePixel);
        handleEventPurchase(quantidadePixel, valorPixel, userId);
        Swal.fire({
          title: 'Pagamento Aprovado!',
          icon: 'success',
          html: 'Seu pagamento foi <b>aprovado</b>',
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setStatus('aprovado');
            location.reload();
          }
        });
      } else if (data.type === 'newNumbers') {
        setCotas((prevCotas) => [...prevCotas, ...data.data]);
        console.log('aqui', cotas);
      }
    });

    socket.on('disconnect', (reason) => {});

    return () => {
      socket.close();
    };
  };

  return (
    <>
      <div className="w-full h-full max-w-full min-h-screen flex flex-col bg-gray-300">
        <HeaderMain />
        <div className="flex w-full h-full p-2">
          <div className="w-full h-full flex flex-col bg-white rounded-xl justify-center items-center p-0 lg:p-2">
            <div className="flex flex-col p-2 w-full h-full lg:w-2/3 shadow-xl border rounded-xl items-center">
              {status === 'pendente' ? (
                <>
                  <div className="flex flex-col w-full h-full items-center justify-center">
                    <div className="w-full max-w-full flex flex-row items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6em"
                        height="6em"
                        viewBox="0 0 24 24"
                      >
                        <g>
                          <path fill="#fea358" d="M7 3H17V7.2L12 12L7 7.2V3Z">
                            <animate
                              id="eosIconsHourglass0"
                              fill="freeze"
                              attributeName="opacity"
                              begin="0;eosIconsHourglass1.end"
                              dur="1.5s"
                              from="1"
                              to="0"
                            />
                          </path>
                          <path
                            fill="#fea358"
                            d="M17 21H7V16.8L12 12L17 16.8V21Z"
                          >
                            <animate
                              fill="freeze"
                              attributeName="opacity"
                              begin="0;eosIconsHourglass1.end"
                              dur="1.5s"
                              from="0"
                              to="1"
                            />
                          </path>
                          <path
                            fill="#fea358"
                            d="M6 2V8H6.01L6 8.01L10 12L6 16L6.01 16.01H6V22H18V16.01H17.99L18 16L14 12L18 8.01L17.99 8H18V2H6ZM16 16.5V20H8V16.5L12 12.5L16 16.5ZM12 11.5L8 7.5V4H16V7.5L12 11.5Z"
                          />
                          <animateTransform
                            id="eosIconsHourglass1"
                            attributeName="transform"
                            attributeType="XML"
                            begin="eosIconsHourglass0.end"
                            dur="0.375s"
                            from="0 12 12"
                            to="180 12 12"
                            type="rotate"
                          />
                        </g>
                      </svg>
                      <div className="mx-1 flex flex-col">
                        <h1 className="text-2xl font-bold">
                          Aguardando pagamento!
                        </h1>
                        <h1>Realize seu pagamento!</h1>
                      </div>
                    </div>
                    <div className="my-2 p-4 border-y w-full text-center">
                      <h1>Seu pedido expira em {tempoExpiracao} minutos!</h1>
                    </div>
                    <img
                      className="w-32 h-32 p-2 border rounded-xl shadow-sm"
                      src={`data:image/png;base64,${qrCode64}`}
                      alt=""
                    />
                    <div className="flex flex-row w-full items-center justify-center">
                      <div className="flex w-full overflow-x-auto mt-2 p-2 border rounded-xl shadow-inner">
                        {qrCodeCC}
                      </div>
                      <div className="relative">
                        <button
                          className="ml-2 p-2 bg-green-500 rounded-xl w-fit h-2/3 text-white hover:bg-green-600 transition ease-in-out"
                          onClick={handleCopy}
                        >
                          Copiar
                        </button>
                        {copied && (
                          <div className="text-xs absolute bottom-11 left-2 mt-2 p-2 bg-black bg-opacity-80 text-white rounded-md">
                            Copiado!
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : status === 'aprovado' ? (
                <>
                  <div className="flex flex-col w-full h-full items-center justify-center px-1">
                    <div className="w-full max-w-full flex flex-row items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6em"
                        height="6em"
                        viewBox="0 0 48 48"
                      >
                        <defs>
                          <mask id="ipTCheckOne0">
                            <g
                              fill="none"
                              stroke="#fff"
                              strokeLinejoin="round"
                              strokeWidth="4"
                            >
                              <path
                                fill="#555"
                                d="M24 44a19.937 19.937 0 0 0 14.142-5.858A19.937 19.937 0 0 0 44 24a19.938 19.938 0 0 0-5.858-14.142A19.937 19.937 0 0 0 24 4A19.938 19.938 0 0 0 9.858 9.858A19.938 19.938 0 0 0 4 24a19.937 19.937 0 0 0 5.858 14.142A19.938 19.938 0 0 0 24 44Z"
                              />
                              <path
                                strokeLinecap="round"
                                d="m16 24l6 6l12-12"
                              />
                            </g>
                          </mask>
                        </defs>
                        <path
                          fill="#00c23a"
                          d="M0 0h48v48H0z"
                          mask="url(#ipTCheckOne0)"
                        />
                      </svg>
                      <div className="mx-1 flex flex-col">
                        <h1 className="text-2xl font-bold">
                          Pagamento aprovado!
                        </h1>
                        <h1>Verifique seus números aqui!</h1>
                      </div>
                    </div>
                    <div></div>
                    {cotaPremiada !== '' ? (
                      <>
                        <div className="mt-3 flex flex-col w-full p-2 bg-green-300 bg-opacity-50 rounded-xl border border-green-900 items-center justify-center">
                          <h1 className="font-bold text-2xl text-emerald-950">
                            Parabéns!
                          </h1>
                          <div className="flex flex-row items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="2em"
                              height="2em"
                              viewBox="0 0 32 32"
                            >
                              <g fill="none">
                                <g filter="url(#f1566idf)">
                                  <path
                                    fill="url(#f1566id0)"
                                    d="M16 29c8.668 0 13-5.82 13-13S24.668 3 16 3S3 8.82 3 16s4.332 13 13 13"
                                  />
                                </g>
                                <path
                                  fill="url(#f1566id1)"
                                  d="M16 29c8.668 0 13-5.82 13-13S24.668 3 16 3S3 8.82 3 16s4.332 13 13 13"
                                />
                                <path
                                  fill="#bb1d80"
                                  d="M5 3h12L3 16V5a2 2 0 0 1 2-2"
                                />
                                <path
                                  fill="url(#f1566idm)"
                                  d="M5 3h12L3 16V5a2 2 0 0 1 2-2"
                                />
                                <path
                                  fill="#5092ff"
                                  d="M8.414 3L3 8.414V5.586L5.586 3zm5 0L3 13.414v-2.828L10.586 3z"
                                />
                                <g filter="url(#f1566idg)">
                                  <circle
                                    cx="23.688"
                                    cy="9.952"
                                    r="1.101"
                                    fill="#e37847"
                                    fill-opacity="0.75"
                                    transform="rotate(28.149 23.688 9.952)"
                                  />
                                </g>
                                <path
                                  stroke="url(#f1566id2)"
                                  strokeLinecap="round"
                                  stroke-width="1.65"
                                  d="M24.75 8.75c1.538 0 3-1.25 3-3"
                                />
                                <path
                                  stroke="url(#f1566id3)"
                                  strokeLinecap="round"
                                  stroke-width="1.65"
                                  d="M6.75 27.75c1.538 0 3-1.25 3-3"
                                />
                                <circle
                                  cx="26"
                                  cy="28"
                                  r="1"
                                  fill="url(#f1566id4)"
                                />
                                <circle
                                  cx="9"
                                  cy="20"
                                  r="1"
                                  fill="url(#f1566id5)"
                                />
                                <circle
                                  cx="24"
                                  cy="3"
                                  r="1"
                                  fill="url(#f1566id6)"
                                />
                                <g filter="url(#f1566idh)">
                                  <path
                                    stroke="#bb582b"
                                    strokeLinecap="round"
                                    stroke-linejoin="round"
                                    stroke-opacity="0.5"
                                    stroke-width="1.65"
                                    d="M19.5 19.997h4.929a2.698 2.698 0 0 0 2.697-2.637l.017-.776a1.94 1.94 0 0 0-1.939-1.982v0a1.55 1.55 0 0 0-1.551 1.551v.873c0 .501.406.907.907.907v0a.907.907 0 0 0 .908-.907v-.284"
                                  />
                                </g>
                                <path
                                  stroke="#00a6ed"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.65"
                                  d="M18.648 19.83h7.854a3.043 3.043 0 0 0 3.042-2.956l.008-.29a2.762 2.762 0 0 0-2.761-2.84h-.062a2.147 2.147 0 0 0-2.148 2.148v.314c0 .716.58 1.297 1.297 1.297v0c.716 0 1.296-.58 1.296-1.297v-.047"
                                />
                                <path
                                  stroke="url(#f1566id7)"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.65"
                                  d="M18.648 19.83h7.854a3.043 3.043 0 0 0 3.042-2.956l.008-.29a2.762 2.762 0 0 0-2.761-2.84h-.062a2.147 2.147 0 0 0-2.148 2.148v.314c0 .716.58 1.297 1.297 1.297v0c.716 0 1.296-.58 1.296-1.297v-.047"
                                />
                                <path
                                  stroke="url(#f1566id8)"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.65"
                                  d="M18.648 19.83h7.854a3.043 3.043 0 0 0 3.042-2.956l.008-.29a2.762 2.762 0 0 0-2.761-2.84h-.062a2.147 2.147 0 0 0-2.148 2.148v.314c0 .716.58 1.297 1.297 1.297v0c.716 0 1.296-.58 1.296-1.297v-.047"
                                />
                                <path
                                  stroke="url(#f1566id9)"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.65"
                                  d="M18.648 19.83h7.854a3.043 3.043 0 0 0 3.042-2.956l.008-.29a2.762 2.762 0 0 0-2.761-2.84h-.062a2.147 2.147 0 0 0-2.148 2.148v.314c0 .716.58 1.297 1.297 1.297v0c.716 0 1.296-.58 1.296-1.297v-.047"
                                />
                                <g filter="url(#f1566idi)">
                                  <path
                                    stroke="#a0d9ff"
                                    strokeLinecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="0.5"
                                    d="M18.367 19.69h7.854a3.043 3.043 0 0 0 3.041-2.957l.009-.289a2.762 2.762 0 0 0-2.761-2.84h-.062a2.147 2.147 0 0 0-2.148 2.148v.314c0 .716.58 1.296 1.297 1.296v0c.716 0 1.296-.58 1.296-1.296v-.048"
                                  />
                                </g>
                                <g filter="url(#f1566idj)">
                                  <circle
                                    cx="27.336"
                                    cy="16"
                                    r=".383"
                                    fill="#c7e8ff"
                                  />
                                </g>
                                <path
                                  fill="url(#f1566ida)"
                                  d="M18 16.24a1 1 0 1 0 0 2a.295.295 0 1 1 0 .59a1 1 0 0 0 0 2a.295.295 0 1 1 0 .592a1 1 0 1 0 0 2a2.295 2.295 0 0 0 1.895-3.591A2.295 2.295 0 0 0 18 16.24"
                                />
                                <g filter="url(#f1566idk)">
                                  <path
                                    stroke="url(#f1566idb)"
                                    strokeLinecap="round"
                                    stroke-width="1.65"
                                    d="M18.895 12.677s.55-1.493 1.951-1.226c1.815.345 1.951 2.299 1.951 2.299"
                                  />
                                </g>
                                <path
                                  stroke="url(#f1566idc)"
                                  strokeLinecap="round"
                                  stroke-width="1.8"
                                  d="M18.648 13.06c.215-.548.765-1.641 2.33-1.641c1.506 0 2.104 1.093 2.334 1.64"
                                />
                                <g filter="url(#f1566idl)">
                                  <path
                                    stroke="url(#f1566idd)"
                                    strokeLinecap="round"
                                    stroke-width="1.65"
                                    d="M9.512 12.672s.55-1.493 1.951-1.227c1.815.346 1.951 2.3 1.951 2.3"
                                  />
                                </g>
                                <path
                                  stroke="url(#f1566ide)"
                                  strokeLinecap="round"
                                  stroke-width="1.8"
                                  d="M9.47 13.06c.216-.548.766-1.641 2.33-1.641c1.507 0 2.105 1.093 2.335 1.64"
                                />
                                <defs>
                                  <radialGradient
                                    id="f1566id0"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="rotate(132.839 10.338 10.168)scale(34.8273)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#fff478" />
                                    <stop offset=".475" stop-color="#ffb02e" />
                                    <stop offset="1" stop-color="#f70a8d" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id1"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="rotate(90 1.625 14.376)scale(16.6092)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop
                                      offset=".824"
                                      stop-color="#f59639"
                                      stop-opacity="0"
                                    />
                                    <stop offset="1" stop-color="#ff63c4" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id2"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-2.07814 -2.48437 10.52297 -8.80232 27.89 8.914)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop offset=".359" stop-color="#ff42b1" />
                                    <stop offset=".854" stop-color="#f70a8d" />
                                    <stop offset="1" stop-color="#ce336e" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id3"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-2.07814 -2.48437 10.52297 -8.80232 9.89 27.914)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop offset=".359" stop-color="#ff42b1" />
                                    <stop offset=".854" stop-color="#f70a8d" />
                                    <stop offset="1" stop-color="#ce336e" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id4"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-1 1 -1 -1 26.5 27.5)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#45c9ff" />
                                    <stop offset="1" stop-color="#3e80ff" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id5"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-1 1 -1 -1 9.5 19.5)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#45c9ff" />
                                    <stop offset="1" stop-color="#3e80ff" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id6"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-1 1 -1 -1 24.5 2.5)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#45c9ff" />
                                    <stop offset="1" stop-color="#3e80ff" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id7"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(4.59375 .78125 -1.32293 7.77884 21.438 15.32)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop offset=".328" stop-color="#1b6ad1" />
                                    <stop
                                      offset="1"
                                      stop-color="#2a80f0"
                                      stop-opacity="0"
                                    />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id8"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(1.0625 .5625 -.80992 1.52985 26.188 16.055)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#1b6ad1" />
                                    <stop
                                      offset="1"
                                      stop-color="#2a80f0"
                                      stop-opacity="0"
                                    />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id9"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-.21875 -4.3125 17.74559 -.90013 27.781 22.008)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#1b6ad1" />
                                    <stop
                                      offset=".602"
                                      stop-color="#2a80f0"
                                      stop-opacity="0"
                                    />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566ida"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(5.92629 0 0 6.45766 17 19.83)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#3e3537" />
                                    <stop offset=".4" stop-color="#442932" />
                                    <stop offset=".676" stop-color="#6a5662" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566idb"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-1.86733 2.94335 -5.0929 -3.23105 22.072 12.094)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#ca7a1b" />
                                    <stop
                                      offset=".634"
                                      stop-color="#ca7a1b"
                                      stop-opacity="0"
                                    />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566idc"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-.1749 -6.53686 9.16333 -.24518 20.98 13.213)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop offset=".167" stop-color="#482641" />
                                    <stop offset=".276" stop-color="#503a4a" />
                                    <stop offset=".401" stop-color="#483637" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566idd"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-1.86733 2.94335 -5.0929 -3.23105 12.69 12.09)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#ca7a1b" />
                                    <stop
                                      offset=".634"
                                      stop-color="#ca7a1b"
                                      stop-opacity="0"
                                    />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566ide"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-.1749 -6.53686 9.16333 -.24518 11.803 13.213)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop offset=".167" stop-color="#482641" />
                                    <stop offset=".276" stop-color="#503a4a" />
                                    <stop offset=".401" stop-color="#483637" />
                                  </radialGradient>
                                  <filter
                                    id="f1566idf"
                                    width="28"
                                    height="28"
                                    x="3"
                                    y="1"
                                    color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feColorMatrix
                                      in="SourceAlpha"
                                      result="hardAlpha"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    />
                                    <feOffset dx="2" dy="-2" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite
                                      in2="hardAlpha"
                                      k2="-1"
                                      k3="1"
                                      operator="arithmetic"
                                    />
                                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                                    <feBlend
                                      in2="shape"
                                      result="effect1_innerShadow_6810_5230"
                                    />
                                  </filter>
                                  <filter
                                    id="f1566idg"
                                    width="6.203"
                                    height="6.203"
                                    x="20.587"
                                    y="6.85"
                                    color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feGaussianBlur
                                      result="effect1_foregroundBlur_6810_5230"
                                      stdDeviation="1"
                                    />
                                  </filter>
                                  <filter
                                    id="f1566idh"
                                    width="11.144"
                                    height="8.896"
                                    x="17.75"
                                    y="12.852"
                                    color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feGaussianBlur
                                      result="effect1_foregroundBlur_6810_5230"
                                      stdDeviation=".5"
                                    />
                                  </filter>
                                  <filter
                                    id="f1566idi"
                                    width="13.405"
                                    height="8.586"
                                    x="17.116"
                                    y="12.354"
                                    color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feGaussianBlur
                                      result="effect1_foregroundBlur_6810_5230"
                                      stdDeviation=".5"
                                    />
                                  </filter>
                                  <filter
                                    id="f1566idj"
                                    width="2.766"
                                    height="2.766"
                                    x="25.953"
                                    y="14.617"
                                    color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feGaussianBlur
                                      result="effect1_foregroundBlur_6810_5230"
                                      stdDeviation=".5"
                                    />
                                  </filter>
                                  <filter
                                    id="f1566idk"
                                    width="6.402"
                                    height="4.832"
                                    x="17.645"
                                    y="10.168"
                                    color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feGaussianBlur
                                      result="effect1_foregroundBlur_6810_5230"
                                      stdDeviation=".25"
                                    />
                                  </filter>
                                  <filter
                                    id="f1566idl"
                                    width="6.402"
                                    height="4.832"
                                    x="8.262"
                                    y="10.163"
                                    color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feGaussianBlur
                                      result="effect1_foregroundBlur_6810_5230"
                                      stdDeviation=".25"
                                    />
                                  </filter>
                                  <linearGradient
                                    id="f1566idm"
                                    x1="3"
                                    x2="16"
                                    y1="18.945"
                                    y2="4.133"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#5092ff" />
                                    <stop
                                      offset=".859"
                                      stop-color="#5092ff"
                                      stop-opacity="0"
                                    />
                                  </linearGradient>
                                </defs>
                              </g>
                            </svg>
                            <p className="text-sm montserrat-500 text-emerald-950 mx-0 lg:mx-2 text-center">
                              Você adquiriu a premiaçao na modalidade título
                              premiado!
                            </p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="2em"
                              height="2em"
                              viewBox="0 0 32 32"
                            >
                              <g fill="none">
                                <g filter="url(#f1566idf)">
                                  <path
                                    fill="url(#f1566id0)"
                                    d="M16 29c8.668 0 13-5.82 13-13S24.668 3 16 3S3 8.82 3 16s4.332 13 13 13"
                                  />
                                </g>
                                <path
                                  fill="url(#f1566id1)"
                                  d="M16 29c8.668 0 13-5.82 13-13S24.668 3 16 3S3 8.82 3 16s4.332 13 13 13"
                                />
                                <path
                                  fill="#bb1d80"
                                  d="M5 3h12L3 16V5a2 2 0 0 1 2-2"
                                />
                                <path
                                  fill="url(#f1566idm)"
                                  d="M5 3h12L3 16V5a2 2 0 0 1 2-2"
                                />
                                <path
                                  fill="#5092ff"
                                  d="M8.414 3L3 8.414V5.586L5.586 3zm5 0L3 13.414v-2.828L10.586 3z"
                                />
                                <g filter="url(#f1566idg)">
                                  <circle
                                    cx="23.688"
                                    cy="9.952"
                                    r="1.101"
                                    fill="#e37847"
                                    fill-opacity="0.75"
                                    transform="rotate(28.149 23.688 9.952)"
                                  />
                                </g>
                                <path
                                  stroke="url(#f1566id2)"
                                  strokeLinecap="round"
                                  stroke-width="1.65"
                                  d="M24.75 8.75c1.538 0 3-1.25 3-3"
                                />
                                <path
                                  stroke="url(#f1566id3)"
                                  strokeLinecap="round"
                                  stroke-width="1.65"
                                  d="M6.75 27.75c1.538 0 3-1.25 3-3"
                                />
                                <circle
                                  cx="26"
                                  cy="28"
                                  r="1"
                                  fill="url(#f1566id4)"
                                />
                                <circle
                                  cx="9"
                                  cy="20"
                                  r="1"
                                  fill="url(#f1566id5)"
                                />
                                <circle
                                  cx="24"
                                  cy="3"
                                  r="1"
                                  fill="url(#f1566id6)"
                                />
                                <g filter="url(#f1566idh)">
                                  <path
                                    stroke="#bb582b"
                                    strokeLinecap="round"
                                    stroke-linejoin="round"
                                    stroke-opacity="0.5"
                                    stroke-width="1.65"
                                    d="M19.5 19.997h4.929a2.698 2.698 0 0 0 2.697-2.637l.017-.776a1.94 1.94 0 0 0-1.939-1.982v0a1.55 1.55 0 0 0-1.551 1.551v.873c0 .501.406.907.907.907v0a.907.907 0 0 0 .908-.907v-.284"
                                  />
                                </g>
                                <path
                                  stroke="#00a6ed"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.65"
                                  d="M18.648 19.83h7.854a3.043 3.043 0 0 0 3.042-2.956l.008-.29a2.762 2.762 0 0 0-2.761-2.84h-.062a2.147 2.147 0 0 0-2.148 2.148v.314c0 .716.58 1.297 1.297 1.297v0c.716 0 1.296-.58 1.296-1.297v-.047"
                                />
                                <path
                                  stroke="url(#f1566id7)"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.65"
                                  d="M18.648 19.83h7.854a3.043 3.043 0 0 0 3.042-2.956l.008-.29a2.762 2.762 0 0 0-2.761-2.84h-.062a2.147 2.147 0 0 0-2.148 2.148v.314c0 .716.58 1.297 1.297 1.297v0c.716 0 1.296-.58 1.296-1.297v-.047"
                                />
                                <path
                                  stroke="url(#f1566id8)"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.65"
                                  d="M18.648 19.83h7.854a3.043 3.043 0 0 0 3.042-2.956l.008-.29a2.762 2.762 0 0 0-2.761-2.84h-.062a2.147 2.147 0 0 0-2.148 2.148v.314c0 .716.58 1.297 1.297 1.297v0c.716 0 1.296-.58 1.296-1.297v-.047"
                                />
                                <path
                                  stroke="url(#f1566id9)"
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1.65"
                                  d="M18.648 19.83h7.854a3.043 3.043 0 0 0 3.042-2.956l.008-.29a2.762 2.762 0 0 0-2.761-2.84h-.062a2.147 2.147 0 0 0-2.148 2.148v.314c0 .716.58 1.297 1.297 1.297v0c.716 0 1.296-.58 1.296-1.297v-.047"
                                />
                                <g filter="url(#f1566idi)">
                                  <path
                                    stroke="#a0d9ff"
                                    strokeLinecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="0.5"
                                    d="M18.367 19.69h7.854a3.043 3.043 0 0 0 3.041-2.957l.009-.289a2.762 2.762 0 0 0-2.761-2.84h-.062a2.147 2.147 0 0 0-2.148 2.148v.314c0 .716.58 1.296 1.297 1.296v0c.716 0 1.296-.58 1.296-1.296v-.048"
                                  />
                                </g>
                                <g filter="url(#f1566idj)">
                                  <circle
                                    cx="27.336"
                                    cy="16"
                                    r=".383"
                                    fill="#c7e8ff"
                                  />
                                </g>
                                <path
                                  fill="url(#f1566ida)"
                                  d="M18 16.24a1 1 0 1 0 0 2a.295.295 0 1 1 0 .59a1 1 0 0 0 0 2a.295.295 0 1 1 0 .592a1 1 0 1 0 0 2a2.295 2.295 0 0 0 1.895-3.591A2.295 2.295 0 0 0 18 16.24"
                                />
                                <g filter="url(#f1566idk)">
                                  <path
                                    stroke="url(#f1566idb)"
                                    strokeLinecap="round"
                                    stroke-width="1.65"
                                    d="M18.895 12.677s.55-1.493 1.951-1.226c1.815.345 1.951 2.299 1.951 2.299"
                                  />
                                </g>
                                <path
                                  stroke="url(#f1566idc)"
                                  strokeLinecap="round"
                                  stroke-width="1.8"
                                  d="M18.648 13.06c.215-.548.765-1.641 2.33-1.641c1.506 0 2.104 1.093 2.334 1.64"
                                />
                                <g filter="url(#f1566idl)">
                                  <path
                                    stroke="url(#f1566idd)"
                                    strokeLinecap="round"
                                    stroke-width="1.65"
                                    d="M9.512 12.672s.55-1.493 1.951-1.227c1.815.346 1.951 2.3 1.951 2.3"
                                  />
                                </g>
                                <path
                                  stroke="url(#f1566ide)"
                                  strokeLinecap="round"
                                  stroke-width="1.8"
                                  d="M9.47 13.06c.216-.548.766-1.641 2.33-1.641c1.507 0 2.105 1.093 2.335 1.64"
                                />
                                <defs>
                                  <radialGradient
                                    id="f1566id0"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="rotate(132.839 10.338 10.168)scale(34.8273)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#fff478" />
                                    <stop offset=".475" stop-color="#ffb02e" />
                                    <stop offset="1" stop-color="#f70a8d" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id1"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="rotate(90 1.625 14.376)scale(16.6092)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop
                                      offset=".824"
                                      stop-color="#f59639"
                                      stop-opacity="0"
                                    />
                                    <stop offset="1" stop-color="#ff63c4" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id2"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-2.07814 -2.48437 10.52297 -8.80232 27.89 8.914)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop offset=".359" stop-color="#ff42b1" />
                                    <stop offset=".854" stop-color="#f70a8d" />
                                    <stop offset="1" stop-color="#ce336e" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id3"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-2.07814 -2.48437 10.52297 -8.80232 9.89 27.914)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop offset=".359" stop-color="#ff42b1" />
                                    <stop offset=".854" stop-color="#f70a8d" />
                                    <stop offset="1" stop-color="#ce336e" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id4"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-1 1 -1 -1 26.5 27.5)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#45c9ff" />
                                    <stop offset="1" stop-color="#3e80ff" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id5"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-1 1 -1 -1 9.5 19.5)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#45c9ff" />
                                    <stop offset="1" stop-color="#3e80ff" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id6"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-1 1 -1 -1 24.5 2.5)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#45c9ff" />
                                    <stop offset="1" stop-color="#3e80ff" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id7"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(4.59375 .78125 -1.32293 7.77884 21.438 15.32)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop offset=".328" stop-color="#1b6ad1" />
                                    <stop
                                      offset="1"
                                      stop-color="#2a80f0"
                                      stop-opacity="0"
                                    />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id8"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(1.0625 .5625 -.80992 1.52985 26.188 16.055)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#1b6ad1" />
                                    <stop
                                      offset="1"
                                      stop-color="#2a80f0"
                                      stop-opacity="0"
                                    />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566id9"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-.21875 -4.3125 17.74559 -.90013 27.781 22.008)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#1b6ad1" />
                                    <stop
                                      offset=".602"
                                      stop-color="#2a80f0"
                                      stop-opacity="0"
                                    />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566ida"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(5.92629 0 0 6.45766 17 19.83)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#3e3537" />
                                    <stop offset=".4" stop-color="#442932" />
                                    <stop offset=".676" stop-color="#6a5662" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566idb"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-1.86733 2.94335 -5.0929 -3.23105 22.072 12.094)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#ca7a1b" />
                                    <stop
                                      offset=".634"
                                      stop-color="#ca7a1b"
                                      stop-opacity="0"
                                    />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566idc"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-.1749 -6.53686 9.16333 -.24518 20.98 13.213)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop offset=".167" stop-color="#482641" />
                                    <stop offset=".276" stop-color="#503a4a" />
                                    <stop offset=".401" stop-color="#483637" />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566idd"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-1.86733 2.94335 -5.0929 -3.23105 12.69 12.09)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#ca7a1b" />
                                    <stop
                                      offset=".634"
                                      stop-color="#ca7a1b"
                                      stop-opacity="0"
                                    />
                                  </radialGradient>
                                  <radialGradient
                                    id="f1566ide"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientTransform="matrix(-.1749 -6.53686 9.16333 -.24518 11.803 13.213)"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop offset=".167" stop-color="#482641" />
                                    <stop offset=".276" stop-color="#503a4a" />
                                    <stop offset=".401" stop-color="#483637" />
                                  </radialGradient>
                                  <filter
                                    id="f1566idf"
                                    width="28"
                                    height="28"
                                    x="3"
                                    y="1"
                                    color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feColorMatrix
                                      in="SourceAlpha"
                                      result="hardAlpha"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    />
                                    <feOffset dx="2" dy="-2" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite
                                      in2="hardAlpha"
                                      k2="-1"
                                      k3="1"
                                      operator="arithmetic"
                                    />
                                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                                    <feBlend
                                      in2="shape"
                                      result="effect1_innerShadow_6810_5230"
                                    />
                                  </filter>
                                  <filter
                                    id="f1566idg"
                                    width="6.203"
                                    height="6.203"
                                    x="20.587"
                                    y="6.85"
                                    color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feGaussianBlur
                                      result="effect1_foregroundBlur_6810_5230"
                                      stdDeviation="1"
                                    />
                                  </filter>
                                  <filter
                                    id="f1566idh"
                                    width="11.144"
                                    height="8.896"
                                    x="17.75"
                                    y="12.852"
                                    color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feGaussianBlur
                                      result="effect1_foregroundBlur_6810_5230"
                                      stdDeviation=".5"
                                    />
                                  </filter>
                                  <filter
                                    id="f1566idi"
                                    width="13.405"
                                    height="8.586"
                                    x="17.116"
                                    y="12.354"
                                    color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feGaussianBlur
                                      result="effect1_foregroundBlur_6810_5230"
                                      stdDeviation=".5"
                                    />
                                  </filter>
                                  <filter
                                    id="f1566idj"
                                    width="2.766"
                                    height="2.766"
                                    x="25.953"
                                    y="14.617"
                                    color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feGaussianBlur
                                      result="effect1_foregroundBlur_6810_5230"
                                      stdDeviation=".5"
                                    />
                                  </filter>
                                  <filter
                                    id="f1566idk"
                                    width="6.402"
                                    height="4.832"
                                    x="17.645"
                                    y="10.168"
                                    color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feGaussianBlur
                                      result="effect1_foregroundBlur_6810_5230"
                                      stdDeviation=".25"
                                    />
                                  </filter>
                                  <filter
                                    id="f1566idl"
                                    width="6.402"
                                    height="4.832"
                                    x="8.262"
                                    y="10.163"
                                    color-interpolation-filters="sRGB"
                                    filterUnits="userSpaceOnUse"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feBlend
                                      in="SourceGraphic"
                                      in2="BackgroundImageFix"
                                      result="shape"
                                    />
                                    <feGaussianBlur
                                      result="effect1_foregroundBlur_6810_5230"
                                      stdDeviation=".25"
                                    />
                                  </filter>
                                  <linearGradient
                                    id="f1566idm"
                                    x1="3"
                                    x2="16"
                                    y1="18.945"
                                    y2="4.133"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#5092ff" />
                                    <stop
                                      offset=".859"
                                      stop-color="#5092ff"
                                      stop-opacity="0"
                                    />
                                  </linearGradient>
                                </defs>
                              </g>
                            </svg>
                          </div>
                          <p className="text-sm montserrat-500 text-emerald-950 text-center">
                            <strong>{cotaPremiada}</strong> é o seu título
                            premiado!
                          </p>
                          <p className="text-sm montserrat-500 text-emerald-950 text-center">
                            Nossa equipe entrará em contato o mais rápido
                            possível para lhe entregar seu prêmio!
                          </p>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col w-full h-full items-center justify-center px-1">
                    <div className="w-full max-w-full flex flex-row items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6em"
                        height="6em"
                        viewBox="0 0 48 48"
                      >
                        <circle cx="17" cy="17" r="14" fill="#00acc1" />
                        <circle cx="17" cy="17" r="11" fill="#eee" />
                        <path d="M16 8h2v9h-2z" />
                        <path d="m22.655 20.954l-1.697 1.697l-4.808-4.807l1.697-1.697z" />
                        <circle cx="17" cy="17" r="2" />
                        <circle cx="17" cy="17" r="1" fill="#00acc1" />
                        <path
                          fill="#ffc107"
                          d="m11.9 42l14.4-24.1c.8-1.3 2.7-1.3 3.4 0L44.1 42c.8 1.3-.2 3-1.7 3H13.6c-1.5 0-2.5-1.7-1.7-3"
                        />
                        <path
                          fill="#263238"
                          d="M26.4 39.9c0-.2 0-.4.1-.6s.2-.3.3-.5s.3-.2.5-.3s.4-.1.6-.1s.5 0 .7.1s.4.2.5.3s.2.3.3.5s.1.4.1.6s0 .4-.1.6s-.2.3-.3.5s-.3.2-.5.3s-.4.1-.7.1s-.5 0-.6-.1s-.4-.2-.5-.3s-.2-.3-.3-.5s-.1-.4-.1-.6m2.8-3.1h-2.3l-.4-9.8h3z"
                        />
                      </svg>
                      <div className="mx-1 flex flex-col">
                        <h1 className="text-2xl font-bold">
                          Pagamento expirado!
                        </h1>
                        <h1>Faça uma nova compra para participar!</h1>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="px-1 py-2 flex flex-col w-full items-center">
                <div className="my-3 flex flex-row w-full p-2 border shadow-md rounded-xl">
                  <img
                    className="card-thumb-photo-celular rounded-xl"
                    src={rifa.imagem}
                    alt=""
                  />
                  <div className="flex flex-col ml-2">
                    <h1 className="text-xl montserrat-500">{rifa.nome}</h1>
                    <p className="text-sm">Finalize sua compra para ganhar!</p>
                    <a
                      className="p-2 mt-1 bg-green-500 text-white font-medium hover:bg-green-600 transition ease-in-out cursor-pointer rounded-lg shadow-sm"
                      target="_blank"
                      href={client?.redesSociais?.whatsapp}
                    >
                      <div className="flex flex-row items-center justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.2em"
                          height="1.2em"
                          viewBox="0 0 256 258"
                          className="mr-1"
                        >
                          <defs>
                            <linearGradient
                              id="logosWhatsappIcon0"
                              x1="50%"
                              x2="50%"
                              y1="100%"
                              y2="0%"
                            >
                              <stop offset="0%" stop-color="#1faf38" />
                              <stop offset="100%" stop-color="#60d669" />
                            </linearGradient>
                            <linearGradient
                              id="logosWhatsappIcon1"
                              x1="50%"
                              x2="50%"
                              y1="100%"
                              y2="0%"
                            >
                              <stop offset="0%" stop-color="#f9f9f9" />
                              <stop offset="100%" stop-color="#fff" />
                            </linearGradient>
                          </defs>
                          <path
                            fill="url(#logosWhatsappIcon0)"
                            d="M5.463 127.456c-.006 21.677 5.658 42.843 16.428 61.499L4.433 252.697l65.232-17.104a123 123 0 0 0 58.8 14.97h.054c67.815 0 123.018-55.183 123.047-123.01c.013-32.867-12.775-63.773-36.009-87.025c-23.23-23.25-54.125-36.061-87.043-36.076c-67.823 0-123.022 55.18-123.05 123.004"
                          />
                          <path
                            fill="url(#logosWhatsappIcon1)"
                            d="M1.07 127.416c-.007 22.457 5.86 44.38 17.014 63.704L0 257.147l67.571-17.717c18.618 10.151 39.58 15.503 60.91 15.511h.055c70.248 0 127.434-57.168 127.464-127.423c.012-34.048-13.236-66.065-37.3-90.15C194.633 13.286 162.633.014 128.536 0C58.276 0 1.099 57.16 1.071 127.416m40.24 60.376l-2.523-4.005c-10.606-16.864-16.204-36.352-16.196-56.363C22.614 69.029 70.138 21.52 128.576 21.52c28.3.012 54.896 11.044 74.9 31.06c20.003 20.018 31.01 46.628 31.003 74.93c-.026 58.395-47.551 105.91-105.943 105.91h-.042c-19.013-.01-37.66-5.116-53.922-14.765l-3.87-2.295l-40.098 10.513z"
                          />
                          <path
                            fill="#fff"
                            d="M96.678 74.148c-2.386-5.303-4.897-5.41-7.166-5.503c-1.858-.08-3.982-.074-6.104-.074c-2.124 0-5.575.799-8.492 3.984c-2.92 3.188-11.148 10.892-11.148 26.561s11.413 30.813 13.004 32.94c1.593 2.123 22.033 35.307 54.405 48.073c26.904 10.609 32.379 8.499 38.218 7.967c5.84-.53 18.844-7.702 21.497-15.139c2.655-7.436 2.655-13.81 1.859-15.142c-.796-1.327-2.92-2.124-6.105-3.716s-18.844-9.298-21.763-10.361c-2.92-1.062-5.043-1.592-7.167 1.597c-2.124 3.184-8.223 10.356-10.082 12.48c-1.857 2.129-3.716 2.394-6.9.801c-3.187-1.598-13.444-4.957-25.613-15.806c-9.468-8.442-15.86-18.867-17.718-22.056c-1.858-3.184-.199-4.91 1.398-6.497c1.431-1.427 3.186-3.719 4.78-5.578c1.588-1.86 2.118-3.187 3.18-5.311c1.063-2.126.531-3.986-.264-5.579c-.798-1.593-6.987-17.343-9.819-23.64"
                          />
                        </svg>
                        Quero novidades!
                      </div>
                    </a>
                  </div>
                </div>
                <div className="flex flex-col w-full p-2 border shadow-md rounded-xl">
                  <div className="flex flex-row items-center w-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.2em"
                      height="1.2em"
                      viewBox="0 0 24 24"
                      className="mr-1"
                    >
                      <path
                        fill="black"
                        d="M11.5 16.5h1V11h-1zm.5-6.923q.262 0 .439-.177t.176-.439t-.177-.438T12 8.346t-.438.177t-.177.439t.177.438t.438.177M12.003 21q-1.867 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709M12 20q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
                      />
                    </svg>
                    <h1 className="text-sm">Detalhes da sua compra</h1>
                    <h1 className="text-xs ml-3 text-gray-500">
                      {window.location.href.split('/')[4]}
                    </h1>
                  </div>
                  <div className="flex flex-col w-full mt-3">
                    <h1 className="text-stone-700">
                      <strong>Comprador:</strong> {comprador.nome}
                    </h1>
                    <h1 className="text-stone-700">
                      <strong>Telefone:</strong> {comprador.telefone}
                    </h1>
                    <h1 className="text-stone-700">
                      <strong>Situação:</strong> {status}
                    </h1>
                    <h1 className="text-stone-700">
                      <strong>Data/horário:</strong>{' '}
                      {masks.formatISODateToBrazilian(dataPedido)}
                    </h1>
                    {status !== 'aprovado' ? (
                      <>
                        <h1 className="text-stone-700">
                          <strong>Expira(ou) em:</strong>{' '}
                          {masks.formatISODateToBrazilian(dataExpiracao)}
                        </h1>
                      </>
                    ) : (
                      <></>
                    )}
                    <h1 className="text-stone-700">
                      <strong>Quantidade:</strong> {quantidade}
                    </h1>
                    <h1 className="text-stone-700">
                      <strong>Total:</strong> R$ {valor.toFixed(2)}
                    </h1>
                  </div>
                </div>
                {/* <div className="w-full mt-4 items-center justify-center text-center">
                  Problemas com sua compra? clique{' '}
                  <a
                    className="underline text-green-600 montserrat-500"
                    href=""
                  >
                    aqui!
                  </a>
                </div> */}
              </div>
              {verNumeros ? (
                <>
                  <div className="w-full px-1 py-2">
                    <div className="w-full max-w-full h-full p-2 flex flex-col border rounded-xl shadow-md">
                      <h1 className="text-xs">
                        Caso seus números não estejam aparecendo, recarregue a
                        página!
                      </h1>
                      <h1>Seus números:</h1>
                      <div className="flex flex-wrap w-full max-w-full max-h-96 overflow-y-auto items-center justify-center">
                        {cotas
                          ?.sort((a, b) => parseInt(a) - parseInt(b))
                          ?.map((number) => (
                            <div key={number}>
                              <div className="w-fit h-fit bg-green-500 p-1 text-xs text-white font-medium rounded-md m-1">
                                {number}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
