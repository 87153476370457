import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { Masks } from '../../Masks/masks';

export default function CardMyNumbers({ order }: any) {
  const masks = new Masks();
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState(order?.id);

  return (
    <>
      <div className="flex flex-row p-2 my-1 w-full border rounded-xl shadow-sm items-center">
        <img
          className="card-thumb-photo-celular rounded-xl"
          src={order?.rifa.imagem}
          alt=""
        />
        <div className="flex flex-row w-full justify-between items-center">
          <div className="flex flex-col mx-2">
            <p className="text-xs montserrat-500 text-gray-500">
              {masks.formatISODateToBrazilian(order?.data_pedido)}
            </p>
            <h1 className="montserrat-500">{order?.rifa.nome}</h1>
            <p className="text-xs montserrat-500">Quantidade: {order?.quantidade}</p>
            <p className="text-xs montserrat-500">Total: R$ {(parseFloat(order?.valor)).toFixed(2)}</p>
            <p className={`text-xs montserrat-500 text-white p-1 ${order.status == "aprovado" ? "bg-green-500" : order.status == "expirado" || order.status == 'cancelado' ? "bg-red-500" : "bg-orange-500"} rounded-xl w-fit h-fit`}>
              {order?.status}
            </p>
          </div>
          <button onClick={() => navigate(`/checkout/${orderId}`)} className="p-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.5em"
              height="1.5em"
              viewBox="0 0 24 24"
            >
              <g fill="none" fillRule="evenodd">
                <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                <path
                  fill="#616161"
                  d="M15.707 11.293a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 1 1-1.414-1.414l4.95-4.95l-4.95-4.95a1 1 0 0 1 1.414-1.414z"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
    </>
  );
}
